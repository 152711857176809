import {
  IonAlert,
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonHeader,
  IonIcon,
  IonImg,
  IonRow,
  IonSpinner,
  IonToolbar,
} from "@ionic/react";
import { useSession } from "@src/app/store/helperHooks";
import { logEvent } from "@src/appV2/lib/analytics";
import { Agent, SignUpStatus } from "@src/lib/interface";
import { getAgentByPhone, logOutAgentAuth, logout } from "@store/session";
import { FC, FormEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { OnBoardingComponentProps } from "./model";
import { StyledIonPage } from "./style";
import { USER_EVENTS } from "../../../constants";
import { ONBOARDING_SEGMENT_EVENT_NAMES } from "../constants/ONBOARDING_SEGMENT_EVENT_NAMES";
import { ONBOARDING_STAGES } from "../constants/ONBOARDING_STAGES";
import { STAGES } from "../model";
import { fireOnboardingSegmentEvent } from "../util/segment";

const AgentSignUpInfo1: FC<OnBoardingComponentProps> = ({ agent, nextStagePath }) => {
  const history = useHistory();
  const [agentSignUpStatus, setAgentSignUpStatus] = useState<SignUpStatus>("unknown");
  const [checkingSignUpStatus, setCheckingSignUpStatus] = useState<boolean>(true);
  const { profile } = useSession();
  const [dbAgent, setDBAgent] = useState<Agent | null>(null);

  const dispatch = useDispatch();

  const onNext = async (event?: FormEvent | MouseEvent) => {
    event?.preventDefault();
    history.push(`${nextStagePath}`);
  };

  /**
   * checks if the agent has an abandoned sign up
   */
  const checkAbandonedSignup = async () => {
    try {
      if (profile?.phone && agent) {
        const phone = profile.phone.substr(-10, 10);
        const agent = await getAgentByPhone(phone);
        setDBAgent(agent);

        const { onboardingFlags: { isSignupCompleted } = {} } = agent;
        let signUpStatus: SignUpStatus;
        if (!agent) {
          signUpStatus = "unstarted";
        } else if (agent && isSignupCompleted) {
          signUpStatus = "complete";
        } else {
          signUpStatus = "inprogress";
        }
        setAgentSignUpStatus(signUpStatus);
      }
    } catch (error) {
      logEvent(USER_EVENTS.ONBOARDING_ERROR, {
        reason: "Unable to check abandoned sign up",
        agent: agent?.userId as string,
        phone: profile?.phone as string,
      });
    } finally {
      setCheckingSignUpStatus(false);
    }
  };

  useEffect(() => {
    checkAbandonedSignup();
  }, []);

  /**
   * starts over the sign up flow
   */
  const onStartOverSignUp = () => {
    try {
      if (!dbAgent) {
        return;
      }
      fireOnboardingSegmentEvent(ONBOARDING_SEGMENT_EVENT_NAMES.RESTARTED_SIGNUP, {
        hcpId: dbAgent.userId as string,
      });
    } catch (error) {
      logEvent(USER_EVENTS.ONBOARDING_ERROR, {
        reason: "Unable to start over sign up flow",
        message: error?.message,
      });
    }
  };

  /**
   * redirects to the last screen in the signup flow
   */
  const onContinueSignUp = () => {
    try {
      if (!dbAgent) {
        return;
      }

      fireOnboardingSegmentEvent(ONBOARDING_SEGMENT_EVENT_NAMES.CONTINUED_SIGNUP, {
        hcpId: dbAgent.userId as string,
        phone: dbAgent.phone as string,
      });

      let { onboardingFlags: { signupStage } = {} } = dbAgent;
      if (signupStage === ONBOARDING_STAGES.NAME) {
        signupStage = "agentLastName";
      }
      if (STAGES[signupStage as string]) {
        history.push(STAGES[signupStage as string].nextStagePath);
      }
    } catch (error) {
      logEvent(USER_EVENTS.ONBOARDING_ERROR, {
        reason: "Unable to continue sign up flow",
        message: error?.message,
      });
    }
  };

  /**
   * redirect to login screen
   */
  const onLogIn = () => {
    dispatch(logOutAgentAuth());
    history.push("/welcome/login");
  };

  return (
    <StyledIonPage className="onboarding-page">
      <IonHeader no-border className="onboarding-header">
        <IonToolbar className="onboarding-toolbar">
          <IonButtons slot="end">
            <IonButton
              className="top-btns close"
              fill="clear"
              slot="icon-only"
              size="large"
              onClick={() => dispatch(logout())}
              color="dark"
            >
              <IonIcon icon="close" />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent className="ion-padding">
        <div className="signup-content content-layout">
          <div className="form-container">
            <IonRow>
              <IonCol sizeMd="8" offsetMd="2" offsetLg="4" sizeLg="4">
                <div className="cbh-logoh-wrapper">
                  <IonImg src="/assets/logo/logo-horizontal.svg" class="logo" />
                </div>
                <div className="form-heading">
                  <h4>Setting you up</h4>
                  <p>
                    We just need a bit more information about you to show you the best shifts for
                    you!
                  </p>
                </div>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol>
                <ul className="cbh-welcome-text infosteps">
                  <li>
                    <span className="step doing">1</span>
                    <h4>Your personal information</h4>
                    <p>We’ll ask for your name, email and address</p>
                  </li>
                  <li>
                    <span className="step">2</span>
                    <h4>Your professional information</h4>
                    <p>
                      Tell us which licenses you have and which types of shifts you’re interested in
                    </p>
                  </li>
                  <li>
                    <span className="step">3</span>
                    <h4>Your contract and pay information</h4>
                    <p>You can get all set up to receive instant payments from Clipboard Health</p>
                  </li>
                  <li>
                    <span className="step">4</span>
                    <h4>Your required documents</h4>
                    <p>Submit a few documents to qualify to book your first shifts</p>
                  </li>
                </ul>
              </IonCol>
            </IonRow>
          </div>

          <div className="signupform-footer footer-container">
            <form onSubmit={onNext}>
              <IonButton
                expand="block"
                size="large"
                class="ion-margin-top ion-margin-bottom continue-button"
                onClick={onNext}
                disabled={checkingSignUpStatus}
              >
                {checkingSignUpStatus ? "Checking Signup Status" : "Let's go"}
                {checkingSignUpStatus && (
                  <IonSpinner slot="end" class="ion-margin-start" name="lines" />
                )}
              </IonButton>
            </form>
          </div>
        </div>

        <IonAlert
          isOpen={agentSignUpStatus === "inprogress"}
          header="It looks like you have an abandoned signup"
          message="Start over, or continue where you left off"
          backdropDismiss={false}
          buttons={[
            {
              text: "Start Over",
              handler: onStartOverSignUp,
            },
            {
              text: "Continue",
              handler: onContinueSignUp,
            },
          ]}
        />
        <IonAlert
          isOpen={agentSignUpStatus === "complete"}
          header="Account with this phone already exists"
          message="Login using your data"
          backdropDismiss={false}
          buttons={[
            {
              text: "Log In",
              handler: onLogIn,
            },
          ]}
        />
      </IonContent>
    </StyledIonPage>
  );
};

export { AgentSignUpInfo1 };
